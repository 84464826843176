<template>
  <div></div>
</template>

<script>
  import userManager from '@/modules/auth/user-manager';

  export default {
    name: 'Login',
    created() {
      const returnUrl = this.$route.query.returnUrl;
      const storageKey = 'login_return_url';
      if (returnUrl) {
        sessionStorage.setItem(storageKey, returnUrl);
      } else {
        sessionStorage.removeItem(storageKey);
      }
      userManager.signinRedirect({
        extraQueryParams: {
          base_url: window.location.origin,
        },
      });
    },
  };
</script>
