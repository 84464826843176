<template>
  <div class="bar-chart-tooltip">
    <div class="bar-chart-tooltip__top">
      <div class="bar-chart-tooltip__categories">
        <div v-for="item in categories" :key="`categories-${item.id}`" class="bar-chart-tooltip__category">
          <Tooltip v-if="longString(item)" class="tooltip-component--mobile-big">
            <span>{{ item.fullName }}</span>
          </Tooltip>
          <span class="bar-chart-tooltip__category-text">{{ item.abbreviated || item.fullName }}</span>
        </div>
      </div>
      <BarChart :chart-data="seriesProp" :height="height" :options="options" css-classes="bar-chart-tooltip__graph" />
    </div>
    <ul class="bar-chart-tooltip__legend">
      <li v-for="(legend, index) in legends" :key="index">
        <span class="bar-chart-tooltip__legend-point" :style="`background: ${legend.backgroundColor}`"></span>
        <span class="bar-chart-tooltip__legend-name">{{ legend.label }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
  import { BarChart } from 'vue-chart-3';

  import numberFormatter from '@/common/mixins/numberFormatter';
  import Tooltip from '@/common/ui/Tooltip.vue';

  export default {
    name: 'BarChartTooltip',
    components: { Tooltip, BarChart },
    mixins: [numberFormatter],
    props: {
      seriesProp: {
        type: Object,
        default: () => {
          return {
            labels: [],
            datasets: [],
          };
        },
      },
      categories: {
        type: Array,
        default: () => [],
      },
      height: {
        type: Number,
        default: 352,
      },
      maxLength: {
        type: [String, Number],
        default: 38,
      },
    },
    data() {
      return {
        maxLengthLine: this.maxLength,
        legends: JSON.parse(JSON.stringify(this.seriesProp?.datasets)),
        options: {
          indexAxis: 'y',
          locale: 'ru-RU',
          clip: false,
          layout: {
            padding: {
              right: 70,
            },
          },
          plugins: {
            tooltip: { enabled: false },
            legend: {
              display: false,
            },
            datalabels: {
              clamp: true,
              align: 'end',
              offset: 0,
              anchor: 'end',
              color: '#231F20',
              family: 'Fira Sans',
              textAlign: 'end',
              font: {
                fontSize: 12,
                lineHeight: 1,
              },
              formatter: (val) => {
                return new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 1 }).format(val);
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                font: {
                  size: 0,
                },
              },
            },
            y: {
              grid: {
                display: false,
              },
              ticks: {
                font: {
                  size: 0,
                },
              },
            },
          },
        },
      };
    },
    mounted() {
      if (window.innerWidth < 1023) {
        this.maxLengthLine = 29;
      }
    },
    methods: {
      longString(item) {
        if (!item.fullName) return false;
        return item.abbreviated || item.fullName.length > this.maxLengthLine;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import './styles/BarChartTooltip.scss';
</style>
