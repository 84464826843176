<template>
  <section class="dashboard">
    <div class="dashboard-content">
      <div class="notification-wrapper">
        <NotificationComponent v-if="userLoaded" class="notification-component" />
      </div>
      <div class="menu-wrapper">
        <menu-component v-if="userLoaded" class="menu-component" />
      </div>
      <div class="dashboard-wrapper">
        <HeaderComponent v-if="userLoaded" class="dashboard-header" />
        <div class="content">
          <router-view :key="$route.path"> </router-view>
        </div>
      </div>
    </div>
    <FooterComponent class="dashboard-footer" />
  </section>
</template>

<script>
  import FooterComponent from '@/common/components/FooterComponent';
  import HeaderComponent from '@/common/components/HeaderComponent';
  import menuComponent from '@/common/components/menuComponent.vue';
  import NotificationComponent from '@/common/components/NotificationComponent';
  import connection from '@/common/message-hub';

  export default {
    name: 'DashboardLayout',
    components: { FooterComponent, HeaderComponent, menuComponent, NotificationComponent },
    beforeUnmount() {
      this.closeConnection();
    },
    computed: {
      userLoaded() {
        return this.$store.state.auth?.oidc?.user != null;
      },
    },
    created() {
      if (this.userLoaded) this.initializeMessageHub();
    },
    watch: {
      userLoaded: function (value) {
        if (value) {
          this.initializeMessageHub();
        } else this.closeConnection();
      },
    },
    methods: {
      initializeMessageHub() {
        console.log('MessageHub Connection Initialization');
        connection.onclose(() => {
          this.startMessageHub();
        });
        this.startMessageHub();
      },
      startMessageHub() {
        let startedPromise = connection.start().catch((err) => {
          console.error('Failed to connect with hub', err);
          return new Promise((resolve, reject) =>
            setTimeout(() => this.startMessageHub().then(resolve).catch(reject), 5000),
          );
        });
        return startedPromise;
      },
      closeConnection() {
        connection.close();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .dashboard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-wrapper {
      width: 100%;
      margin: 0 auto;
    }
    &-content {
      display: flex;
      height: 100%;
      min-height: 100vh;
    }
  }
  .menu-wrapper {
    transform: translateX(-100%);
    transition: transform ease 0.33s;
    z-index: 11;

    @at-root .active-left-menu & {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 280px;
      background: linear-gradient(90deg, #2d86cc -0.88%, #36b7b4 99.77%);
      transform: translateX(0);
      z-index: 1110;
    }
  }

  .menu-component {
    background: transparent;

    &.active {
      max-height: calc(100vh);
      overflow-y: auto;
    }
  }
  // important
  @media (max-width: 768px) {
    .menu-component.active {
      max-width: unset;
      width: 100%;
    }
    .menu-component.active ~ .dashboard-wrapper {
      position: absolute;
    }
  }
  // important
</style>
