const nsi = '/api/nsi';

export default {
  directives: {
    countries: '/api/country/search',
    groupsCountries: '/api/countrygroup/search',
    typeOfTradingOperation: `${nsi}/nsitradingoperation/search`,
    transport: `${nsi}/nsitransport/search`,
    typeImport: '/api/prioritydestinationimport/importkind',
    processType: `${nsi}/nsinavigatorprocesstype/search`,
    procedure: '/api/procedure/search',
    operations: '/api/step/search',
    nsiCountryWithData: `${nsi}/countryListWithData`,
    nsiMeasureOrganProtectedMarketSearch: `${nsi}/nsimeasureorganprotectedmarket/search`,
    nsiIndustry: `${nsi}/nsiindustry/search`,
    nsiMoveDirections: `${nsi}/moveDirections`,
    nsiCountryCoverages: `${nsi}/countryCoverages`,
    nsiMeasureTypes: `${nsi}/measureTypes`,
    nsiRequestTtrStatus: `${nsi}/nsirequestttrstatus/search`,
    nsiInvestigationType: `${nsi}/nsiinvestigationtype/search`,
    regions: '/api/FERDictionaries/GetRFRegions',
    partnerCountry: '/api/FERDictionaries/GetCountries',
    federalDistricts: '/api/ferdictionaries/getfederaldistricts',
  },
  nsitnved: {
    search: `${nsi}/nsitnved/search`,
    byIDs: `${nsi}/nsitnved/listbyids`,
  },
  investmentAgreement: {
    getList: '/api/investmentagreement/search',
    find: '/api/investmentagreement/find',
    CRUD: '/api/investmentagreement',
  },
  countryAnalytics: {
    payments: {
      currentBalance: '/api/BalanceOfPayment/CurrentAccountAndComponentsItems',
      financialBalance: '/api/BalanceOfPayment/FinancialAccountAndComponentsItems',
      infoCurrentBalance: '/api/balanceofpayment/currentaccountandcomponentsvalues',
      infoFinancialBalance: '/api/balanceofpayment/financialaccountandcomponentsvalues',
      infoReserveAssetBalance: '/api/balanceofpayment/reserveassetbalancevalues',
      infoPureErrorsAndOmissions: '/api/balanceofpayment/pureerrorsandomissionsvalues',
      yearList: '/api/balanceofpayment/getyearlist',
    },
    priorityImportAndImportDestination: {
      getInfo: '/api/prioritydestinationimport/search',
      loadFile: '/api/prioritydestinationimport/upload',
    },
    analytics: {
      getLifeIndexSummary: 'api/lifeindex/summary',
      getInvestmentInStock: 'api/investment/instock',
      getInvestmentOutStock: 'api/investment/outstock',
      getInvestmentInFlows: 'api/investment/inflows',
      getInvestmentOutFlows: 'api/investment/outflows',
      getForeignTradeTurnoverWordBalance: 'api/foreigntradeturnoverworld/balance',
      getForeignTradeTurnoverWordGdpValues: 'api/foreigntradeturnoverworld/gdpvalues',
      getUnfriendlyCountryInfo: 'api/unfriendlyсountries/unfriendlycountryinfo',
      getTopImportIndustries: 'api/foreigntradeturnoverworld/top5importindustries',
      getTopExportIndustries: 'api/foreigntradeturnoverworld/top5exportindustries',
      getTopImportCountries: 'api/foreigntradeturnoverworld/top5importcountries',
      getTopExportCountries: 'api/foreigntradeturnoverworld/top5exportcountries',
      getIndicatorBudgetDeficit: 'api/keyindicators/budgetdeficit',
      getIndicatorStockMarketCapitalization: 'api/keyindicators/stockmarketcapitalization',
      getIndicatorFinancialAccount: 'api/keyindicators/financialaccount',
      getIndicatorGovernmentBondYields: 'api/keyindicators/governmentbondyields',
      getYearListTurnover: 'api/foreignTradeTurnoverWorld/yearList',
      getForeignTradeData: 'api/foreigntrade/summary',
      getMacroEconomicIndicatorValue: 'api/macroeconomicindicatorcountryvalue/search',
      getEconomicReviewSummary: 'api/economicsReview/summary',
      getIndicatorCountryRating: 'api/keyindicators/countryratings',
      getExportProductLast: 'api/exportProduct/summaryLast',
      getFerRegionsTradeValue: 'api/ferregionstrade/getvalue',
      getFerRegionsTradeCountryCount: 'api/ferregionstrade/getcountrycount',
      getQuartalList: 'api/favortrade/getquartallist',
      getQuartalSummary: '/api/favortrade/getquartalsummary',
    },
  },
  navigator: {
    productGroups: {
      search: '/api/grouptnvedftt/search',
      find: '/api/grouptnvedftt/find',
      CRUD: '/api/grouptnvedftt',
    },
    scenarios: {
      search: '/api/scenario/search',
    },
    stepCards: {
      getStepCard: '/api/step/find/',
      saveStepCard: '/api/step',
      getOrganizationList: '/api/organization/search',
      getDocumentList: '/api/navigatordocument/search',
      getLegalSourceList: '/api/legalsource/search',
    },
  },
  importAnalytics: {
    getUnfriendlyCountriesSearch: '/api/unfriendlyсountries/search',
    loadUnfriendlyCountriesReport: 'api/unfriendlyсountries/uploaddata',
  },
  measure: {
    singleTtrRegistry: {
      CRUD: '/api/singlettr',
      changeParametersMulti: '/api/singlettr/changeparametersmulti',
      findAuditLog: '/api/singlettraudit/findauditlog',
    },
    unilateralNonTrRegistry: {
      CRUD: '/api/unilateralnontr',
      changeParametersMulti: '/api/unilateralnontr/changeparametersmulti',
      findAuditLog: '/api/unilateralnontraudit/findauditlog',
    },
    initiativeRegistry: {
      changeParametersMulti: '/api/requestinitiativettr/changestatusmulti',
    },
  },
  ves: {
    getYearFirst: '/api/ferdictionaries/getfirstdatayear',
    events: {
      search: '/api/ferregionsanalytics/getfilteredevents',
      getEventCard: '/api/ferregionsanalytics/getevent',
      downloadExcel: '/api/ferregionsanalytics/exporteventstoexcel',
      downloadPDF: '/api/ferregionsanalytics/exporteventtopdf',
    },
    economicRelations: {
      getPassportKeyData: 'api/ferpassportkeydata/get',
      getPassportPartnerCountriesData: 'api/ferpassportpartnercountries/getdata',
      getPassportPartnerCountriesCount: 'api/ferpassportpartnercountries/getpartnerscount',
      getPassportTradeTurnover: 'api/ferpassporttradeinfo/getturnover',
      getPassportMainData: 'api/ferpassportmaindata/getmaindata',
      getPassportCurrentEvents: 'api/ferpassportevents/getcurrentevents',
      getPassportPreviousEvents: 'api/ferpassportevents/getpreviousevents',
      getPassportCurrentEventsCount: 'api/ferpassportevents/getcurrenteventscount',
      getPassportPreviousEventsCount: 'api/ferpassportevents/getpreviouseventscount',
      getPassportAgreements: 'api/ferpassportagreements/getagreements',
      getResponsibleAuthority: 'api/ferresponsibleauthority/getresponsibleauthority',

      topRegionsAgreements: '/api/fergeneralinfo/getregionsstatistic',
      topEfficiency: '/api/fergeneralinfo/gettopeffectiveregions',
      topRegionsByTurnover: '/api/fergeneralinfo/gettopregionsbyturnover',
      topRegionsByExport: '/api/fergeneralinfo/gettopregionsbyexport',
      topRegionsByImport: '/api/fergeneralinfo/gettopregionsbyimport',
      topRegionsByEvents: '/api/fergeneralinfo/gettopregionsbyevents',
      topRegionsByAgreements: '/api/fergeneralinfo/gettopregionsbyagreements',
      topMonthRegionsByEvents: '/api/fergeneralinfo/getmonthtopregionsbyevents',
      topMonthRegionsByAgreements: '/api/fergeneralinfo/getmonthtopregionsbyagreements',
    },
    implementation: {
      getEventsHistory: '/api/ferhistory/getfilteredeventshistory',
      getAgreementsHistory: '/api/ferhistory/getfilteredagreementshistory',
      exportEvents: '/api/ferhistory/exporteventshistorytoexcel',
      exportAgreements: '/api/ferhistory/exportagreementshistorytoexcel',
    },
  },
  mpkProject: {
    getMpkProjectDepartment: '/api/department/search',
    getMpkProjectMinRole: '/api/nsi/getnsiminroles',
    getMpkProjectList: '/api/nsi/getnsiprojectlists',
    getMpkProjectSupportMeasure: '/api/nsi/getnsisupportmeasures',
    getMpkProjectCategoryList: '/api/nsi/getnsiprojectcategories',
    getMpkProjectTypeEntry: '/api/nsi/getnsitypeentries',
    getMpkListProjects: '/api/mpkproject/searchlight',
    getMpkProjectSearchLight: '/api/mpkproject/searchlight',
    getMpkProjectSearch: '/api/mpkproject/SearchWithArchiveData',
  },
};
