<template>
  <div class="investmentCooperationRussia">
    <div class="investmentCooperationRussia__climate">
      <span class="investmentCooperationRussia__headTitle">Инвестиции и инвестиционный климат</span>
      <div class="investmentCooperationRussia__climate__raitingBlock">
        <span class="investmentCooperationRussia__headTitle">Кредитный рейтинг</span>
        <Tooltip :triggers="['click']" class="tooltip-component--mobile-big">
          <span class="investmentCooperationRussia__helpButton__text">
            Moody’s / S&P / Fitch. Зеленый цвет — инвестиционный рейтинг, темно-оранжевый — спекулятивный.
          </span>
        </Tooltip>
        <p v-if="isNotEmptyCountryCreditRating" class="investmentCooperationRussia__climate__raitingBlock__info">
          <span :class="countryCreditRatingClass.moody">{{ countryCreditRating.moody || '–' }}</span>
          <span>/</span><span :class="countryCreditRatingClass.snP">{{ countryCreditRating.snP || '–' }}</span
          ><span>/</span><span :class="countryCreditRatingClass.dbrs">{{ countryCreditRating.dbrs || '–' }}</span>
        </p>
        <p v-else class="investmentCooperationRussia__climate__raitingBlock__info">Нет данных</p>
      </div>
    </div>
    <div class="investmentCooperationRussia__inRussiaRaitingBlock">
      <span class="investmentCooperationRussia__headTitle">{{ getNameInRussiaRaiting }}</span>
      <div
        class="investmentCooperationRussia__inRussiaRaitingBlock__contentBlock"
        v-if="investmentRussia?.bars?.length"
      >
        <div class="investmentCooperationRussia__inRussiaRaitingBlock__contentBlock__raiting">
          <ChartColumnAndLine
            v-if="getFdiInData.inRussiaLabels"
            :xData="getFdiInData.inRussiaLabels"
            :columnYData="getFdiInData.inRussiaColumnYData"
            :columnYName="getInRussiaRaitingColumnYName"
            :lineYData="getFdiInData.inRussiaLineYData"
            :lineYName="getInRussiaRaitingLineYName"
            dataLabelsText=""
            :lineYTooltipPrefix="lineYTooltipPrefix"
            :colors="['#0393BA', '#73EA48']"
            :enableSecondYAxis="true"
            :marker="inRussiaRaitingMarker"
            :stroke="inRussiaRaitingStroke"
            sizeXYLabel="14"
            sizeDataLabel="13"
            :offsetYDataLabel="-10"
            :height="400"
            :tickAmount="tickAmount"
            :tickAmountSecond="tickAmount"
            :format-y-label-function="getLeadingInvestorsLabelFormat"
          />
        </div>
        <div
          class="investmentCooperationRussia__inRussiaRaitingBlock__contentBlock__infoBlock"
          v-if="investmentRussia?.position > 0"
        >
          <span class="investmentCooperationRussia__inRussiaRaitingBlock__contentBlock__infoBlock__title">
            Среди стран-инвесторов в Россию {{ summary?.countryName || '' }} находится на:
          </span>
          <div
            class="investmentCooperationRussia__inRussiaRaitingBlock__contentBlock__infoBlock__positionBlock"
            v-if="investmentRussia?.position"
          >
            <span
              class="investmentCooperationRussia__inRussiaRaitingBlock__contentBlock__infoBlock__positionBlock__title"
              >{{ investmentRussia.position }} место</span
            >
            <span
              class="investmentCooperationRussia__inRussiaRaitingBlock__contentBlock__infoBlock__positionBlock__text"
              >по статистике Банка России</span
            >
          </div>
          <div
            class="investmentCooperationRussia__inRussiaRaitingBlock__contentBlock__infoBlock__positionBlock"
            v-if="investmentRussia?.positionAdjusted"
          >
            <span
              class="investmentCooperationRussia__inRussiaRaitingBlock__contentBlock__infoBlock__positionBlock__title investmentCooperationRussia__inRussiaRaitingBlock__contentBlock__infoBlock__positionBlock__title_green"
              >{{ investmentRussia.positionAdjusted }} место</span
            >
            <span
              class="investmentCooperationRussia__inRussiaRaitingBlock__contentBlock__infoBlock__positionBlock__text"
            >
              по статистике Банка России с корректировкой на перечень стран-промежуточных инвесторов
              <Tooltip :triggers="['click']" class="tooltip-component--mobile-big tooltip-component--investment">
                <span class="investmentCooperationRussia__helpButton__text">
                  Из рейтинга исключаются офшорные, низконалоговые и промежуточные юрисдикции, определнные в перечнях и
                  материалах Минфина и ФНС России, ЮНКТАД и ОЭСР
                </span>
              </Tooltip>
            </span>
          </div>
        </div>
      </div>
      <div class="investmentCooperationRussia__noData" v-else>Нет данных</div>
    </div>
    <div v-if="isShowIndustryInvestmentsBlock" class="investmentCooperationRussia__investmentDirections">
      <span class="investmentCooperationRussia__headTitle"
        >Основные направления инвестирования в Россию в {{ exportData.reportYear }} году</span
      >
      <div
        class="investmentCooperationRussia__investmentDirections__contentBlock"
        v-if="topThreeIndustries?.industriyInvestments?.length"
      >
        <ul class="investmentCooperationRussia__investmentDirections__contentBlock__itemsBlock">
          <li
            class="investmentCooperationRussia__investmentDirections__contentBlock__itemsBlock__item"
            v-for="item in topThreeIndustries.industriyInvestments"
            :key="item"
          >
            {{ item.name }} ({{ convertAmount(item.value) }})
          </li>
        </ul>
      </div>
      <div class="investmentCooperationRussia__noData" v-else>Нет данных</div>
    </div>

    <div v-if="isShowTopFiveInvestorsBlock" class="investmentCooperationRussia__leadingInvestors">
      <span class="investmentCooperationRussia__headTitle"
        >Топ-5 стран инвесторов в {{ countryData?.accusativeCase }} в {{ exportData.reportYear }} году, млрд $</span
      >
      <div v-if="topFiveInvestors.length" class="investmentCooperationRussia__leadingInvestors__contentBlock">
        <ColumnChart :chart-data="getLeadingInvestors.data" :labels="getLeadingInvestors.labels" />
      </div>
      <p v-else class="investmentCooperationRussia__noData">Нет данных</p>
    </div>

    <div class="investmentCooperationRussia__tradingServices">
      <span class="investmentCooperationRussia__headTitle">
        Торговля услугами России с {{ countryData.instrumentalCase }} в {{ exportData.reportYear }} году
      </span>
      <div class="investmentCooperationRussia__tradingServices__contentBlock" v-if="tradServExpData">
        <div class="investmentCooperationRussia__tradingServices__contentBlock__chartBlock">
          <div class="investmentCooperationRussia__tradingServices__contentBlock__chartBlock__infoBlock">
            <span class="investmentCooperationRussia__tradingServices__contentBlock__chartBlock__infoBlock__text">
              Экспорт&emsp;{{ convertAmount(exportData.totalValue) }} </span
            >&emsp;
            <span
              :class="`investmentCooperationRussia__tradingServices__contentBlock__chartBlock__infoBlock__text
              ${getDifferenceColor(exportData.difference)}`"
            >
              {{ getDifference(exportData.difference) }}% г/г
            </span>
          </div>
          <div class="investmentCooperationRussia__tradingServices__contentBlock__chartBlock__chart">
            <BarChartTooltip
              class="bar-chart-tooltip--investments"
              :seriesProp="tradServExpData"
              :categories="tradServExpLabels"
              height="400"
              maxLength="40"
            />
          </div>
        </div>
        <div class="investmentCooperationRussia__tradingServices__contentBlock__chartBlock">
          <div class="investmentCooperationRussia__tradingServices__contentBlock__chartBlock__infoBlock">
            <span class="investmentCooperationRussia__tradingServices__contentBlock__chartBlock__infoBlock__text">
              Импорт&emsp;{{ convertAmount(importData.totalValue) }} </span
            >&emsp;
            <span
              :class="`investmentCooperationRussia__tradingServices__contentBlock__chartBlock__infoBlock__text
              ${getDifferenceColor(exportData.difference)}`"
            >
              {{ getDifference(importData.difference) }}% г/г
            </span>
          </div>
          <div class="investmentCooperationRussia__tradingServices__contentBlock__chartBlock__chart">
            <BarChartTooltip
              class="bar-chart-tooltip--investments"
              :seriesProp="tradServImpData"
              :categories="tradServImpLabels"
              height="400"
              maxLength="40"
              v-if="tradServImpData"
            />
          </div>
        </div>
      </div>
      <div class="investmentCooperationRussia__noData" v-else>Нет данных</div>
    </div>
  </div>
</template>

<script>
  import Constants from '@/common/constants';
  import numberFormatter from '@/common/mixins/numberFormatter';
  import text from '@/common/mixins/text';
  import Tooltip from '@/common/ui/Tooltip.vue';

  import BarChartTooltip from '@/components/ChartComponents/BarChartTooltip.vue';
  import ColumnChart from '@/components/columnChart.vue';

  import Api from '@/modules/countryАnalytics/api';
  import ChartColumnAndLine from '@/modules/foreignMarket/components/chart/ChartColumnAndLine.vue';

  import {
    DbrsInvestRatings,
    DIVIDER_LABEL,
    DIVIDER_LABEL_MILLARD,
    MoodyInvestRatings,
    SnPInvestRatings,
  } from '../../utils/constants.js';

  export default {
    name: 'InvestmentCooperationRussia',

    components: {
      ColumnChart,
      Tooltip,
      ChartColumnAndLine,
      BarChartTooltip,
    },

    mixins: [text, numberFormatter],

    props: {
      countryId: {
        type: String,
        default: '',
      },
      summary: {
        type: Object,
        default: () => {},
      },
    },

    data() {
      return {
        exportData: {},
        importData: {},
        countryCreditRating: {
          moody: null,
          snP: null,
          dbrs: null,
        },
        investmentRussia: {},
        topThreeIndustries: {},
        topFiveInvestors: [],
        countryData: {},
        inRussiaRaitingMarker: {
          size: 6,
          colors: ['#73EA48'],
          strokeColors: '',
          strokeWidth: 0,
        },
        inRussiaRaitingStroke: {
          width: [0, 2],
          curve: 'straight',
          dashArray: [0, 5, 0],
        },
        emptyLabels: ['', '', '', '', '', ''],
      };
    },

    created() {
      this.loadData();
    },

    watch: {
      countryId: function () {
        this.loadData();
      },
    },

    computed: {
      // Экспорт
      tradServExpLabels() {
        if (this.exportData?.currentPeriod) {
          return this.exportData.currentPeriod.map((item, id) => {
            return {
              id,
              fullName: item.name.length > 20 ? item.name : '',
              abbreviated: this.sliceText(item.name),
            };
          });
        }
        return [];
      },

      tradServExpData() {
        if (this.exportData?.currentPeriod || this.exportData?.previousPeriod) {
          const firstData = this.exportData.currentPeriod.map((item) => item.value.toFixed(1));
          const secondData = this.exportData.previousPeriod.map((item) => item.value.toFixed(1));

          return {
            labels: this.emptyLabels,
            datasets: [
              {
                label: `${this.exportData?.reportYear}, млн $`,
                data: firstData,
                backgroundColor: '#0393BA',
              },

              {
                label: `${this.exportData?.previousReportYear}, млн $`,
                data: secondData,
                backgroundColor: '#D1D5DB',
              },
            ],
          };
        }
        return false;
      },

      ////

      // Импорт
      tradServImpLabels() {
        if (this.importData?.currentPeriod) {
          return this.importData.currentPeriod.map((item, id) => {
            return {
              id,
              fullName: item.name.length > 20 ? item.name : '',
              abbreviated: this.sliceText(item.name),
            };
          });
        }
        return [];
      },

      tradServImpData() {
        if (this.importData?.currentPeriod || this.importData?.previousPeriod) {
          const firstData = this.importData.currentPeriod.map((item) => item.value.toFixed(1));
          const secondData = this.importData.previousPeriod.map((item) => item.value.toFixed(1));

          return {
            labels: this.emptyLabels,
            datasets: [
              {
                label: `${this.importData?.reportYear}, млн $`,
                data: firstData,
                backgroundColor: '#0393BA',
              },

              {
                label: `${this.importData?.previousReportYear}, млн $`,
                data: secondData,
                backgroundColor: '#D1D5DB',
              },
            ],
          };
        }
        return false;
      },

      ////

      isShowIndustryInvestmentsBlock() {
        return !!this.topThreeIndustries?.industriyInvestments?.length || this.isEmptyInvestors;
      },

      isShowTopFiveInvestorsBlock() {
        return !!this.topFiveInvestors.length || this.isEmptyInvestors;
      },

      isEmptyInvestors() {
        return !this.topThreeIndustries?.industriyInvestments?.length && !this.topFiveInvestors.length;
      },

      getFdiInData() {
        const data = {
          inRussiaLabels: [],
          inRussiaColumnYData: [],
          inRussiaLineYData: [],
        };

        this.investmentRussia.bars.forEach((item) => {
          const inRussiaLineYNumber =
            this.investmentRussia?.position > 0 ? (item.percent * 100).toFixed(1) : item.percent.toFixed(1);
          data.inRussiaLabels.push(item.year);
          data.inRussiaColumnYData.push(item.value.toFixed(0));
          data.inRussiaLineYData.push(inRussiaLineYNumber);
        });

        return data;
      },

      getNameInRussiaRaiting() {
        return this.investmentRussia?.position > 0
          ? 'ПИИ в Россию, млрд $'
          : `Совокупные ПИИ в ${this.countryData.accusativeCase}, млрд $`;
      },

      getInRussiaRaitingColumnYName() {
        return this.investmentRussia?.position > 0 ? 'Все страны' : 'Запас';
      },

      getInRussiaRaitingLineYName() {
        return this.investmentRussia?.position > 0 ? `Доля ${this.countryData.genetiveCase}` : 'Поток (пр. шкала)';
      },

      lineYTooltipPrefix() {
        return this.investmentRussia?.position > 0 ? '%' : '';
      },
      tickAmount() {
        const values = this.getFdiInData.inRussiaColumnYData.map((el) => Number(el));
        return Math.trunc(Math.max(...values) / this.stepSize) + 1;
      },
      isNotEmptyCountryCreditRating() {
        const { moody, snP, dbrs } = this.countryCreditRating;
        return moody || snP || dbrs;
      },
      countryCreditRatingClass() {
        const { moody, snP, dbrs } = this.countryCreditRating;
        return {
          moody: MoodyInvestRatings.includes(moody) ? 'text-green' : 'text-orange',
          snP: SnPInvestRatings.includes(snP) ? 'text-green' : 'text-orange',
          dbrs: DbrsInvestRatings.includes(dbrs) ? 'text-green' : 'text-orange',
        };
      },
      getLeadingInvestors() {
        const data = {
          data: [],
          labels: [],
        };

        this.topFiveInvestors.forEach((country) => {
          data.labels.push(country.countryName);
          data.data.push((country.amount / 1000).toFixed(1));
        });

        return data;
      },
    },

    methods: {
      loadData() {
        this.investmentRussia = {};
        this.topFiveInvestors = [];

        Api.getCountryCreditRating(this.countryId)
          .then((response) => {
            const data = response?.data;
            if (data) {
              if (data.moody || data.snP || data.dbrs) {
                this.countryCreditRating.moody = data?.moody ?? '';
                this.countryCreditRating.snP = data?.snP ?? '';
                this.countryCreditRating.dbrs = data?.dbrs ?? '';
              }
            }
          })
          .catch((err) => {
            console.error(err);
            this.countryCreditRating = {
              moody: null,
              snP: null,
              dbrs: null,
            };
          });

        Api.getInvestmentRussia(this.countryId)
          .then((response) => {
            if (response?.data) {
              response.data.bars.sort((a, b) => {
                return Number(a.year) - Number(b.year);
              });
              this.investmentRussia = response.data;
            }
          })
          .catch((err) => {
            console.error(err);
            this.investmentRussia = {};
          });

        Api.getTopThreeIndustries(this.countryId)
          .then((response) => {
            this.topThreeIndustries = response?.data;

            if (!this.topThreeIndustries?.industriyInvestments?.length) {
              this.initTopFiveInvestors();
            }
          })
          .catch((err) => {
            console.error(err);
            this.topThreeIndustries = {};
            this.initTopFiveInvestors();
          });

        Api.getFavorTrade(this.countryId, true)
          .then((response) => {
            this.exportData = response?.data;
          })
          .catch((err) => {
            console.error(err);
            this.exportData = {};
          });

        Api.getFavorTrade(this.countryId, false)
          .then((response) => {
            this.importData = response?.data;
          })
          .catch((err) => {
            console.error(err);
            this.importData = {};
          });

        Api.findCountry(this.countryId)
          .then((response) => {
            this.countryData = response?.data;
          })
          .catch((err) => {
            console.error(err);
            this.countryData = {};
          });
      },

      async initTopFiveInvestors() {
        try {
          const response = await Api.getTopFiveInvestors(this.countryId);
          this.topFiveInvestors = response?.data?.investmentData ?? [];
        } catch (err) {
          console.error(err);
          this.topFiveInvestors = [];
        }
      },

      sliceText(value) {
        return value.length > 20 ? value.slice(0, 20) + '...' : value;
      },

      convertAmount(val) {
        const calculatedValue = val >= 1 ? val : val * Constants.THOUSAND;
        const divider = val >= 1 ? DIVIDER_LABEL_MILLARD : DIVIDER_LABEL;

        return `${this.formatNumber(calculatedValue, 1, ' ', ',', true)} ${divider} $`;
      },
      getDifference(val) {
        const valueFix = this.numberFormatter(val, 'ru-RU', 1);
        return val > 0 ? `+${valueFix}` : valueFix;
      },

      getDifferenceColor(val) {
        return val > 0 ? 'green' : val == 0 ? 'gray' : 'red';
      },
      getLeadingInvestorsLabelFormat(value) {
        return this.formatNumber(value, 0, ' ');
      },
    },
  };
</script>

<style lang="scss">
  .investmentCooperationRussia {
    display: flex;
    flex-direction: column;
    gap: 40px;

    &__headTitle {
      font: $fira-20-M;
      color: $base;
      margin: 0 0 24px 0;
    }

    &__helpButton {
      .tooltip-component__content {
        &__text {
          font: $fira-14;
        }
      }
    }

    &__climate {
      display: flex;
      flex-direction: column;

      &__raitingBlock {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        gap: 16px 8px;

        & .investmentCooperationRussia__headTitle {
          margin: 0;
        }

        & .investmentCooperationRussia__helpButton {
          margin: 0 16px 0 8px;
        }

        &__info,
        &__info > span {
          font: $fira-20-M;
          color: $base;
        }
      }
    }

    &__inRussiaRaitingBlock {
      display: flex;
      flex-direction: column;
      width: 100%;

      & .chartBar {
        width: 100%;
      }

      &__contentBlock {
        display: flex;
        width: 100%;
        gap: 16px;

        &__raiting {
          flex: 1;
          width: 100%;

          @media screen and (max-width: 1024px) {
            overflow: hidden;
            overflow-x: auto;
          }
        }

        &__infoBlock {
          display: flex;
          flex-direction: column;
          gap: 16px;
          flex: 1;

          &__title {
            font: $fira-16;
            color: $base;
          }

          &__positionBlock {
            display: flex;
            flex-direction: column;

            &__title {
              font: $fira-24-M;
              color: $blue;
            }

            &__title_green {
              color: $green;
            }

            &__text {
              position: relative;
              font: $fira-16;
              color: $base;
              display: inline-block;

              .investmentCooperationRussia__helpButton .tooltip-component__content {
                right: 0;
                left: auto;
              }
            }
          }
        }

        @media screen and (max-width: 1024px) {
          flex-direction: column;
        }
      }
    }

    &__investmentDirections {
      width: 100%;
      display: flex;
      flex-direction: column;

      &__contentBlock {
        width: 100%;

        &__itemsBlock {
          list-style: inside;
          display: flex;
          flex-direction: column;
          gap: 10px;

          &__item {
            font: $fira-20-M;
            color: #17a1fa;
          }
        }
      }
    }

    &__tradingServices {
      width: 100%;
      display: flex;
      flex-direction: column;

      &__contentBlock {
        display: flex;
        align-items: flex-start;

        &__chartBlock {
          display: flex;
          flex-direction: column;
          gap: 16px;
          flex: 1;
          flex: 1;

          &__infoBlock {
            display: flex;
            gap: 5px;

            &__text {
              font: $fira-20-M;
              color: $blue;

              .green {
                color: $green;
              }

              .red {
                color: $red;
              }

              .gray {
                color: $light-grey-5;
              }
            }
          }

          &__chart {
            @media screen and (max-width: 1280px) {
              overflow: hidden;
            }
          }

          @media screen and (max-width: 1280px) {
            width: 100%;
          }
        }

        @media screen and (max-width: 1280px) {
          flex-direction: column;
          gap: 24px;
        }

        .bar-chart-tooltip {
          &__categories {
            width: auto;
          }

          &__category {
            height: calc(100% / 6);
            justify-content: flex-start;
          }

          &__graph {
            width: 100%;
          }

          @media (max-width: $tablet) {
            &__categories {
              width: auto;
            }

            &__graph {
              width: 100%;
            }
          }
        }
      }
    }

    &__leadingInvestors {
      display: flex;
      flex-direction: column;
      overflow-x: auto;
      padding: 0 0 10px 0;

      &__contentBlock {
        width: 768px;
      }

      & .chartBar {
        width: 100%;
      }
    }
  }
  .text-orange {
    color: #ff8000 !important;
  }
</style>
