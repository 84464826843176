<template>
  <div class="country-ranking">
    <h2>Позиции в мировых рейтингах</h2>
    <ul>
      <li class="d-flex">
        <span class="indicators__item-green indicatorBlock"> </span>
        - Инвестиционный
      </li>
      <li class="d-flex">
        <span class="indicators__item-yellow indicatorBlock"> </span>
        - Спекулятивный
      </li>
      <li class="d-flex">
        <span class="indicators__item-red indicatorBlock"> </span>
        - Пред дефолтный
      </li>
    </ul>
    <ul class="indicators__list align-center">
      <li
        v-for="item in indicatorsList"
        :key="item.id"
        class="indicators__item"
        :class="[
          { 'indicators__item-green': item.id == 1 && ratingStyleName == 'green' },
          { 'indicators__item-yellow': item.id == 1 && ratingStyleName == 'yellow' },
          { 'indicators__item-red': item.id == 1 && ratingStyleName == 'red' },
        ]"
      >
        <p>{{ item.title }}</p>
        <div v-if="item.source" class="analytics-source-text">Источник: {{ item.source }}</div>
        <div v-if="item.dateUpdate" class="analytics-source-text">Дата обновления: {{ item.dateUpdate }}</div>
        <div v-if="item.period" class="analytics-source-text">Период: {{ item.period }}</div>
        <strong v-if="item.count > 0 || (item.id == 1 && item.count)">
          {{ item.count }} <template v-if="item.id !== 1">место</template>
        </strong>
        <strong v-else> - </strong>
        <div v-if="item.awards.length" class="award d-flex flex-column">
          <span v-for="(award, idx) in item.awards" :key="idx"> {{ award.count }} место - {{ award.country }} </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import moment from 'moment';

  import Api from '../../api';

  export default {
    name: 'countryRanking',
    props: {
      countryId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        indicatorsList: [
          {
            id: 1,
            title: 'Суверенные рейтинги\n' + '(S&P, Moody`s, Fitch)',
            source: 'Trading Economics',
            dateUpdate: '09.12.2022',
            count: 'Загрузка...',
            awards: [],
          },
          {
            id: 2,
            title: 'Индекс человеческого развития',
            source: 'Всемирный банк',
            period: '2020 год',
            count: '7',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
          {
            id: 3,
            title: 'Показатель индекса\n' + 'человеческого капитала',
            source: 'Всемирный банк',
            period: '2018 год',
            count: '23',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
          {
            id: 4,
            title: 'Индекс глобальной конкурентоспособности',
            source: 'Trading Economics',
            period: '2019 год',
            count: '7',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
          {
            id: 5,
            title: 'Индекс глобальных финансовых рынков',
            source: 'Long Finance',
            period: '2021 год',
            count: '7',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
          {
            id: 6,
            title: 'Размер ВВП',
            source: 'Всемирный банк',
            period: '2020 год',
            count: '7',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
          {
            id: 7,
            title: 'Индекс развития электронного правительства',
            source: 'Организация объединенных наций',
            period: '2020 год',
            count: '7',
            awards: [
              { country: 'Загрузка...', count: '1' },
              { country: 'Россия', count: '0' },
            ],
          },
        ],
        ratingPoints: {
          moody: [
            { value: 3, ratings: ['AAA', 'A', 'Aaa', 'Aa1', 'Aa2', 'Aa3', 'A1', 'A2', 'A3', 'Baa1', 'Baa2', 'Baa3'] },
            {
              value: 2,
              ratings: [
                'Caa1',
                'B+',
                'BB',
                'Caa3',
                'BB-',
                'Ba1',
                'Ba2',
                'Ba3',
                'B1',
                'B2',
                'B3',
                'Caa',
                'CCC-',
                'B',
                'Caa2',
                'B-',
                'BB+',
              ],
            },
            { value: 1, ratings: ['Ca', 'C', 'SD', 'Ca2'] },
          ],
          standardAndPoor: [
            { value: 3, ratings: ['AAA', 'Aaa', 'AA+', 'AA', 'AA-', 'A+', 'A', 'A-', 'BBB+', 'BBB', 'BBB-', 'Baa2'] },
            {
              value: 2,
              ratings: ['BB+', 'BB', 'BB-', 'B+', 'B', 'B-', 'Ba3', 'CCC+', 'CCC', 'CCC-', 'Caa1', 'B3', 'Ba1', 'B2'],
            },
            { value: 1, ratings: ['CC', 'C'] },
          ],
          fitch: [
            { value: 3, ratings: ['AAA', 'AA+', 'AA', 'AA-', 'A+', 'A', 'A-', 'BBB+', 'BBB', 'BBB-'] },
            { value: 2, ratings: ['BB+', 'BB', 'BB-', 'B+', 'B', 'B-', 'CCC'] },
            { value: 1, ratings: ['DDD', 'DD', 'C'] },
          ],
        },
        ratingStyleName: '',
      };
    },
    created() {
      this.loadRatings();
    },
    methods: {
      loadRatings() {
        Api.getCountryRatings(this.countryId)
          .then((response) => {
            this.updateIndicators(response.data);
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      updateIndicators(data) {
        const noData = '-';
        let ind = this.indicatorsList.find((x) => x.id === 1);
        ind.count = `${data.standardAndPoor || noData} / ${data.moody || noData} / ${data.fitch || noData}`;
        ind.dateUpdate = moment(data.ratingUpdateDate).format('DD.MM.YYYY');
        ind = this.indicatorsList.find((x) => x.id === 2);
        ind.count = data.hdiRank;
        ind.awards[0].country = data.hdiRankWinner;
        ind.awards[1].count = data.russiaHdiRankPlace;
        ind = this.indicatorsList.find((x) => x.id === 3);
        ind.count = data.hciPlace;
        ind.awards[0].country = data.hciWinner;
        ind.awards[1].count = data.russiaHciPlace;
        ind = this.indicatorsList.find((x) => x.id === 4);
        ind.count = data.gciPlace;
        ind.awards[0].country = data.gciWinner;
        ind.awards[1].count = data.russiaGciPlace;

        ind = this.indicatorsList.find((x) => x.id === 5);
        ind.count = data.gfcPlace;
        ind.awards[0].country = data.gfcWinner;
        ind.awards[1].count = data.russiaGfcPlace;
        ind = this.indicatorsList.find((x) => x.id === 6);
        ind.count = data.gdpPlace;
        ind.awards[0].country = data.gdpWinner;
        ind.awards[1].count = data.russiaGdpPlace;
        ind = this.indicatorsList.find((x) => x.id === 7);
        ind.count = data.egdiPlace;
        ind.awards[0].country = data.egdiWinner;
        ind.awards[1].count = data.russiaEgdiPlace;
        this.setRatingBorderStyleName(data);
      },
      setRatingBorderStyleName(data) {
        let ratingCount = 0;
        let value = 0;

        Object.keys(this.ratingPoints).forEach((key) => {
          let rating = this.ratingPoints[key].find((x) => x.ratings.includes(data[key]));
          if (rating) {
            ratingCount++;
            value += rating.value;
          }
        });

        if (ratingCount > 0) {
          value = value / ratingCount;
          if (value > 2.5) this.ratingStyleName = 'green';
          else if (value >= 1.5) this.ratingStyleName = 'yellow';
          else this.ratingStyleName = 'red';
        } else this.ratingStyleName = 'none';
      },
    },
    watch: {
      countryId: function () {
        this.loadRatings();
      },
    },
  };
</script>

<style scoped lang="scss">
  .indicators {
    &__list {
      display: flex;
      flex-wrap: wrap;

      gap: 18px 36px;
      @media (max-width: 1150px) {
        gap: 18px 36px;
      }
    }
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 180px;
      padding: 15px;
      background: #ffffff;
      box-shadow: 0px 0px 17px rgba(55, 55, 55, 0.08);
      border-radius: 5px;
      border: 1px solid #06a0d8a9;
      p {
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 113.9%;
        text-align: center;
        color: #333333;
      }
      strong {
        margin-top: 20px;
        margin-bottom: 9px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #333333;
      }
      span {
        margin-bottom: 4px;
        font-family: 'Fira Sans', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #333333;
      }
      &-green {
        border: 2px solid #00de59c8;
      }
      &-yellow {
        border: 2px solid #ead700c8;
      }
      &-red {
        border: 2px solid #ff0000da;
      }
    }
  }
  h2 {
    margin-bottom: 20px;
    font-family: 'Fira Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 113.9%;
    color: #000000;
  }

  .indicators__item .analytics-source-text {
    font-size: 10px;
    text-align: center;
  }
  .indicatorBlock {
    display: block;
    width: 40px;
    height: 20px;
    margin-right: 8px;
  }
</style>
