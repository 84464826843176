<template>
  <div class="international-trade" :class="{ notForTecRussiaTab: notForTecRussiaTab }">
    <div class="international-trade__switch-block d-flex justify-content-between bgWhite">
      <div class="international-trade__switch">
        <RedesignedSwitchComponent
          :checked="isProductMode"
          @switchHandler="switchHand($event)"
          class="switch-back--big"
        />
      </div>
      <div class="international-trade__year-selection">
        <div class="international-trade__year-label">Источник: {{ isProductMode ? 'ФТС России' : 'Банк России' }}</div>
        <div class="international-trade__select">
          <SelectMultiComponent
            id="period"
            v-model="year"
            :options="yearList"
            placeholder="Период"
            classSelect="multiselect--green multiselect--hidden-clear"
            @select="selectChange"
          />
        </div>
        <div v-if="!isProductMode" class="international-trade__select">
          <SelectMultiComponent
            id="quartal"
            v-model="quartal"
            :options="quartals"
            placeholder="Квартал"
            classSelect="multiselect--green"
            @select="loadDataQuartal"
          />
        </div>
      </div>
    </div>

    <template v-if="!currentSummary">
      <p>Загрузка данных...</p>
    </template>

    <template v-if="currentSummary">
      <h5 class="title title--h5 title--key-trading-indicators">Ключевые показатели торговли</h5>
      <TradeIndicatorsList justifyContent="start">
        <TradeIndicator
          name="Экспорт"
          :divider="divider"
          :currentValue="currentSummary?.[indicatorValue.EXPORT_VALUE]"
          :previousPercent="currentSummary?.[indicatorPreviousPercentValue.EXPORT_VALUE]"
          :isSelected="tabId === tabsID.EXPORT"
          :rating="`${currentSummary[indicatorPlaceInMarket.EXPORT]} место в рос. экспорте`"
          @click="exportMoreInfo ? selectTab(tabsID.EXPORT, 'Экспорт') : null"
        />
        <TradeIndicator
          name="Импорт"
          :divider="divider"
          :currentValue="currentSummary?.[indicatorValue.IMPORT_VALUE]"
          :previousPercent="currentSummary?.[indicatorPreviousPercentValue.IMPORT_VALUE]"
          :isSelected="tabId === tabsID.IMPORT"
          :rating="`${currentSummary[indicatorPlaceInMarket.IMPORT]} место в рос. импорте`"
          @click="importMoreInfo ? selectTab(tabsID.IMPORT, 'Импорт') : null"
        />
        <TradeIndicator
          :name="isProductMode ? 'Товарооборот' : 'Оборот услуг'"
          :divider="divider"
          :currentValue="currentSummary?.[indicatorValue.TOTAL_VALUE]"
          :previousPercent="currentSummary?.[indicatorPreviousPercentValue.TOTAL_VALUE]"
          :isSelected="tabId === tabsID.TRADE_TURNOVER"
          :rating="`${currentSummary[indicatorPlaceInMarket.TRADE_TURNOVER]} место в рос. товарообороте`"
          @click="
            turnoverMoreInfo ? selectTab(tabsID.TRADE_TURNOVER, isProductMode ? 'Товарооборот' : 'Оборот услуг') : null
          "
        />
        <TradeIndicator
          v-if="isProductMode"
          name="ННЭ"
          :divider="divider"
          :currentValue="currentSummary?.[indicatorValue.EXPORT_NON_ENERGY_VALUE]"
          :previousPercent="currentSummary?.[indicatorPreviousPercentValue.EXPORT_NON_ENERGY_VALUE]"
          :isSelected="tabId === tabsID.NON_RAW_MATERIALS"
          @click="exportNonEnergyMoreInfo ? selectTab(tabsID.NON_RAW_MATERIALS, 'Несырьевой') : exportNonEnergyMoreInfo"
        />
        <TradeIndicator
          name="Сальдо"
          :divider="divider"
          :currentValue="currentSummary?.[indicatorValue.BALANCE]"
          :previousPercent="currentSummary?.[indicatorPreviousPercentValue.BALANCE]"
          :previousValue="currentSummary?.[previousValue.BALANCE]"
          :isSelected="tabId === tabsID.BALANCE"
          @click="balanceMoreInfo ? selectTab(tabsID.BALANCE, 'Сальдо') : balanceMoreInfo"
        />
      </TradeIndicatorsList>
    </template>

    <div v-if="notForTecRussiaTab != true">
      <div v-if="tabId && graphByYear">
        <h2 class="mt-5">
          <template v-if="isProductMode">Торговля товарами</template><template v-else>Торговля услугами</template>,
          {{ dividerLabel }} $ США
        </h2>
        <div v-if="visibleLineChartFour">
          <LineChartFour
            v-if="!quartal"
            format-label=" млн $"
            :chart-data="graphByYear.data[0]"
            :chart-data-second="graphByYear.data[1]"
            :chart-data-three="graphByYear.data[2]"
            :chart-data-four="graphByYear.data[3]"
            background-color-three="#2F84EB"
            background-color-four="#31BC00"
            point-color-three="#2F84EB"
            point-color-four="#31BC00"
            background-color="#01A39D"
            point-color="#01A39D"
            background-color-two="#787F8F"
            point-color-two="#787F8F"
            border-color="#01A39D"
            border-color-two="#787F8F"
            border-color-three="#2F84EB"
            border-color-four="#31BC00"
            :labels="graphByYear.years"
            :displayDataLabels="false"
          />

          <LineChartFour
            v-else
            format-label=""
            :chart-data="series[0]?.data"
            :chart-data-second="series[1]?.data"
            :chart-data-three="series[2]?.data"
            :chart-data-four="series[3]?.data"
            background-color-three="#2F84EB"
            background-color-four="#31BC00"
            point-color-three="#2F84EB"
            point-color-four="#31BC00"
            background-color="#01A39D"
            point-color="#01A39D"
            background-color-two="#787F8F"
            point-color-two="#787F8F"
            border-color="#01A39D"
            border-color-two="#787F8F"
            border-color-three="#2F84EB"
            border-color-four="#31BC00"
            :labels="categories"
            :displayDataLabels="false"
            :key="graphKey"
          />

          <div class="indicator-round-wrap">
            <div class="indicator-round d-flex align-items-center">
              <span class="rounded-circle" style="background-color: #01a39d"></span>
              <p>{{ isProductMode ? 'Товарооборот' : 'Оборот услуг' }}</p>
            </div>
            <div class="indicator-round d-flex align-items-center">
              <span class="rounded-circle"></span>
              <p>Экспорт</p>
            </div>
            <div class="indicator-round d-flex align-items-center">
              <span class="rounded-circle"></span>
              <p>Импорт</p>
            </div>
            <div class="indicator-round d-flex align-items-center">
              <span class="rounded-circle"></span>
              <p>Сальдо</p>
            </div>
          </div>
        </div>
        <div v-if="tabId === tabsID.NON_RAW_MATERIALS">
          <LineChartDouble
            format-label=" млн $"
            :chart-data="graphByYear.data[tabsID.NON_RAW_MATERIALS]"
            background-color="#01A39D"
            point-color="#01A39D"
            background-color-two="#787F8F"
            point-color-two="#787F8F"
            border-color="#01A39D"
            border-color-two="#787F8F"
            :labels="graphByYear.years"
            :displayDataLabels="false"
          />
          <div class="d-flex align-items-center mt-4">
            <div class="indicator-round d-flex align-items-center mr-4">
              <span class="rounded-circle"></span>
              <p>Абсолютное значение</p>
            </div>
          </div>
        </div>
      </div>

      <template v-if="isProductMode && currentSummary && tabId">
        <!-- Топ 5 укрупненных групп товаров ТН ВЭД с наибольшим объемом импорта -->
        <InternationalTradeChart
          v-if="selectedTabImport && currentSummary.topEnlargedImportGroups?.length"
          title="Товарная структура. Укрупненные группы"
          nameTable="Импорт"
          typeChart="topEnlargedImportGroups"
          exportGroups="enlargedImportGroupsTotalCurrent"
          exportGroupsPrevious="enlargedImportGroupsTotalPrevious"
          topExportGroups="topEnlargedImportGroups"
          :currentSummary="currentSummary"
          :switchChartProp="switchRows.topImportGroupsTotalPrevious"
          :legendName="nameLegendInternationalTradeChart"
        />
        <!-- Топ 5 групп укрупненных групп товаров ТН ВЭД с наибольшим объемом экспорта -->
        <InternationalTradeChart
          v-if="selectedTabExport && currentSummary.topEnlargedExportGroups?.length"
          title="Товарная структура. Укрупненные группы"
          nameTable="Экспорт"
          typeChart="topEnlargedExportGroups"
          exportGroups="enlargedExportGroupsTotalCurrent"
          exportGroupsPrevious="enlargedExportGroupsTotalPrevious"
          topExportGroups="topEnlargedExportGroups"
          :currentSummary="currentSummary"
          :switchChartProp="switchRows.topEnlargedExportGroups"
          :legendName="nameLegendInternationalTradeChart"
        />

        <InternationalTradeChart
          v-if="!selectedTabNonRawMaterials && !selectedTabImport && currentSummary.topExportCode2?.length"
          title="Товарная структура экспорта"
          nameTable="Экспорт"
          typeChart="topExportCode2"
          exportGroups="exportCode2TotalCurrent"
          exportGroupsPrevious="exportCode2TotalPrevious"
          topExportGroups="topExportCode2"
          :currentSummary="currentSummary"
          :switchChartProp="switchRows.tovarnayaStructureExport"
          :legendName="nameLegendInternationalTradeChart"
        />

        <InternationalTradeChart
          v-if="selectedTabNonRawMaterials && currentSummary.topExportNonEnergyCode2?.length"
          title="Товарная структура экспорта"
          nameTable="Импорт"
          typeChart="topExportNonEnergyCode2"
          exportGroups="exportNonEnergyCode2TotalCurrent"
          exportGroupsPrevious="exportNonEnergyCode2TotalPrevious"
          topExportGroups="topExportNonEnergyCode2"
          :currentSummary="currentSummary"
          :switchChartProp="switchRows.tovarnayaStructureExportSecond"
          :legendName="nameLegendInternationalTradeChart"
        />

        <InternationalTradeChart
          v-if="!selectedTabNonRawMaterials && !selectedTabExport && currentSummary.topImportCode2?.length"
          title="Товарная структура импорта"
          nameTable="Импорт"
          typeChart="topImportCode2"
          exportGroups="importCode2TotalCurrent"
          exportGroupsPrevious="importCode2TotalPrevious"
          topExportGroups="topImportCode2"
          :currentSummary="currentSummary"
          :switchChartProp="switchRows.tovarnayaStructureImport"
          :legendName="nameLegendInternationalTradeChart"
        />

        <template v-if="!selectedTabNonRawMaterials">
          <InternationalTradeChart
            v-if="currentSummary.topExportGroups?.length && !selectedTabImport"
            title="Топ-5 товаров экспорта"
            nameTable="Экспорт"
            typeChart="topExportGroups"
            exportGroups="exportGroupsTotalCurrent"
            exportGroupsPrevious="exportGroupsTotalPrevious"
            topExportGroups="topExportGroups"
            :currentSummary="currentSummary"
            :switchChartProp="switchRows.topTovariExport"
            :legendName="nameLegendInternationalTradeChart"
          />

          <InternationalTradeChart
            v-if="currentSummary.topImportGroups?.length && !selectedTabExport"
            title="Топ-5 товаров импорта"
            nameTable="Импорт"
            typeChart="topImportGroups"
            exportGroups="importGroupsTotalCurrent"
            exportGroupsPrevious="importGroupsTotalPrevious"
            topExportGroups="topImportGroups"
            :currentSummary="currentSummary"
            :switchChartProp="switchRows.topTovariImport"
            :legendName="nameLegendInternationalTradeChart"
          />
        </template>

        <InternationalTradeChart
          v-if="selectedTabNonRawMaterials && currentSummary.topExportNonEnergyGroups?.length"
          title="Топ-5 товаров экспорта"
          nameTable="Экспорт"
          typeChart="topExportNonEnergyGroups"
          exportGroups="exportNonEnergyGroupsTotalCurrent"
          exportGroupsPrevious="exportNonEnergyGroupsTotalPrevious"
          topExportGroups="topExportNonEnergyGroups"
          :currentSummary="currentSummary"
          :switchChartProp="switchRows.topTovariExportSecond"
          :legendName="nameLegendInternationalTradeChart"
        />

        <div
          v-if="selectedTabImport && (currentSummary?.priorityImport?.length || currentSummary?.destinationImport?.length)"
          class="international-trade__wrapper-line-chart"
        >
          <div v-if="currentSummary?.priorityImport?.length" class="international-trade__item-line-chart">
            <!-- Приоритетный импорт -->
            <LineChartPercent name="Приоритетный импорт" :seriesProp="getSeriesPriorityImport" />
          </div>

          <div v-if="currentSummary?.destinationImport?.length" class="international-trade__item-line-chart">
            <!-- Импорт по назначению -->
            <LineChartPercent name="Импорт по назначению" :seriesProp="getSeriesDestinationImport" />
          </div>
        </div>
      </template>

      <div class="mt-5" v-if="!isProductMode && currentSummary && currentSummary.topImportGroups?.length > 0">
        <h2>Туризм</h2>
        <ColumnChartDouble
          label-first="Выезд российских граждан в страну, тыс чел"
          label-second="Въезд иностранных граждан в Российскую Федерацию, тыс чел"
          format-label=" тыс чел"
          :isShowCustomLegend="true"
          :chart-data="currentSummary.topImportGroups.map((item) => item.previousValue.toFixed(0))"
          :chart-data-two="currentSummary.topImportGroups.map((item) => item.currentValue.toFixed(0))"
          :labels="currentSummary.topImportGroups.map((item) => item.title)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { debounce } from 'lodash';

  import RedesignedSwitchComponent from '@/common/components/redesigned/RedesignedSwitchComponent.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import Constants from '@/common/constants';
  import text from '@/common/mixins/text';

  import ColumnChartDouble from '@/components/columnChartDouble';
  import LineChartDouble from '@/components/lineChartDouble';
  import LineChartFour from '@/components/lineChartFour';

  import IndicatorApi from '@/modules/admin/api/indicatorVisibility';
  import Api from '@/modules/countryАnalytics/api';

  import {
    COLOR_DESTINATION_IMPORT,
    COLOR_PRIORITY_IMPORT,
    DIVIDER_LABEL,
    TABS_ID_KEY_TRADING_INDICATORS,
  } from '../../utils/constants';
  import LineChartPercent from '../charts/LineChartPercent.vue';
  import TradeIndicator from './indicators/TradeIndicator.vue';
  import TradeIndicatorsList from './indicators/TradeIndicatorsList.vue';
  import InternationalTradeChart from './InternationalTradeChart.vue';
  import InternationalTradeMixin from './mixin/InternationalTradeMixin';

  export default {
    name: 'InternationalTradeRussia',
    mixins: [text, InternationalTradeMixin],
    props: {
      countryId: {
        type: String,
        default: '',
      },
      notForTecRussiaTab: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      LineChartDouble,
      LineChartFour,
      ColumnChartDouble,
      RedesignedSwitchComponent,
      SelectMultiComponent,
      LineChartPercent,
      InternationalTradeChart,
      TradeIndicator,
      TradeIndicatorsList,
    },
    data() {
      return {
        isProductMode: true,
        tabId: null,
        label: null,

        turnoverMoreInfo: true,
        exportMoreInfo: true,
        importMoreInfo: true,
        exportNonEnergyMoreInfo: true,
        balanceMoreInfo: true,

        divider: Constants.MILLION,
        dividerLabel: DIVIDER_LABEL,
        indicatorValue: Constants.indicator.value,
        previousValue: Constants.indicator.previousValue,
        indicatorPreviousPercentValue: Constants.indicator.previousPercentValue,
        indicatorPlaceInMarket: Constants.indicator.placeInMarket,

        switchRows: {
          tovarnayaStructureExport: true,
          tovarnayaStructureExportSecond: true,
          tovarnayaStructureImport: true,
          topTovariExport: true,
          topTovariImport: true,
          topTovariExportSecond: true,
          topEnlargedExportGroups: true,
          topImportGroupsTotalPrevious: true,
        },
        tabsID: TABS_ID_KEY_TRADING_INDICATORS,
      };
    },

    computed: {
      currentSummary() {
        return this.summary?.summary;
      },

      getSeriesPriorityImport() {
        if (this.currentSummary?.priorityImport) {
          return this.currentSummary.priorityImport.map((i) => ({
            data: i.part,
            name: i.importKindName,
            color: COLOR_PRIORITY_IMPORT[i.importKindId].color,
          }));
        }
        return [];
      },

      getSeriesDestinationImport() {
        if (this.currentSummary?.destinationImport) {
          return this.currentSummary.destinationImport.map((i) => ({
            data: i.part,
            name: i.importKindName,
            color: COLOR_DESTINATION_IMPORT[i.importKindId].color,
          }));
        }
        return [];
      },

      visibleLineChartFour() {
        const arr = [this.tabsID.TRADE_TURNOVER, this.tabsID.EXPORT, this.tabsID.IMPORT, this.tabsID.BALANCE];
        return arr.includes(this.tabId);
      },

      selectedTabImport() {
        return this.tabId === this.tabsID.IMPORT;
      },

      selectedTabExport() {
        return this.tabId === this.tabsID.EXPORT;
      },

      selectedTabNonRawMaterials() {
        return this.tabId === this.tabsID.NON_RAW_MATERIALS;
      },

      nameLegendInternationalTradeChart() {
        return {
          current: `${this.summary.summary.chartPeriodNames.current}, ${this.dividerLabel} $`,
          previous: `${this.summary.summary.chartPeriodNames.previous}, ${this.dividerLabel} $`,
        };
      },
    },
    created() {
      this.loadIndicatorVisibility();
      this.loadYearList(true, true);
      if (!this.isProductMode) {
        this.loadQuartals();
      }
    },
    methods: {
      loadIndicatorVisibility() {
        IndicatorApi.search().then((response) => {
          this.turnoverMoreInfo = response.data[13].moreInfoVisible;
          this.importMoreInfo = response.data[14].moreInfoVisible;
          this.exportMoreInfo = response.data[15].moreInfoVisible;
          this.exportNonEnergyMoreInfo = response.data[16].moreInfoVisible;
          this.balanceMoreInfo = response.data[17].moreInfoVisible;
        });
      },

      loadData() {
        this.summary = null;
        this.graphByYear = null;
        Api.getForeignTradeForOne(true, this.isProductMode, this.year, this.countryId).then((response) => {
          this.summary = response.data;
          this.graphByYear = {
            years: this.summary.summary.valuesByYears.map((x) => x.year),
            data: [
              this.getValuesByYears(this.indicatorValue.TOTAL_VALUE, this.divider),
              this.getValuesByYears(this.indicatorValue.EXPORT_VALUE, this.divider),
              this.getValuesByYears(this.indicatorValue.IMPORT_VALUE, this.divider),
              this.getValuesByYears(this.indicatorValue.BALANCE, this.divider),
              this.getValuesByYears(this.indicatorValue.EXPORT_NON_ENERGY_VALUE, this.divider),
            ],
          };
          this.$forceUpdate();
        });
      },

      switchHand(e) {
        this.isProductMode = e;
        const isQuartal = this.quartal ? 1 : Constants.THOUSAND;
        this.divider = e ? Constants.MILLION : isQuartal;
        this.loadYearList(true, true);
        this.$forceUpdate();
      },

      selectTab(id, label) {
        if (id != this.tabId) {
          this.tabId = id;
          this.label = label;
        } else {
          // при повторном клике - сворачивание
          this.tabId = null;
          this.label = null;
        }
      },

      selectChange: debounce(function () {
        this.clearGraph();
        this.loadData();
        if (!this.isProductMode) {
          this.loadQuartals();
        }
      }, 500),
    },
    watch: {
      countryId() {
        this.loadYearList(true, true);
        this.clearGraph();
        if (!this.isProductMode) {
          this.loadQuartals();
        }
      },
      year() {
        if (!this.isProductMode) {
          this.loadQuartals();
        }
      },
      quartal(val) {
        if (val == null) {
          this.divider = this.isProductMode ? Constants.MILLION : Constants.THOUSAND;
          this.loadData();
        } else {
          this.divider = 1;
        }
      },
      isProductMode(val) {
        if (val) {
          this.quartal = null;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import './src/assets/theme/default/colors';
  @import './styles/InternationalTradeRussia.scss';
  @import '@/assets/theme/default/title.scss';
  @import '../../styles/indicators-wrap.scss';
</style>
