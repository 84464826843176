export const ECONOMIC_RELATIONS = 'economicRelations';

export const ECONOMIC_RELATIONS_ACTIONS_NAME = {
  getPassportKeyData: `${ECONOMIC_RELATIONS}/getPassportKeyData`,
  getPassportPartnerCountriesData: `${ECONOMIC_RELATIONS}/getPassportPartnerCountriesData`,
  getPassportTradeTurnover: `${ECONOMIC_RELATIONS}/getPassportTradeTurnover`,
  getPassportMainData: `${ECONOMIC_RELATIONS}/getPassportMainData`,
  getPassportPartnerCountriesCount: `${ECONOMIC_RELATIONS}/getPassportPartnerCountriesCount`,
  getPassportCurrentEvents: `${ECONOMIC_RELATIONS}/getPassportCurrentEvents`,
  getPassportPreviousEvents: `${ECONOMIC_RELATIONS}/getPassportPreviousEvents`,
  getPassportCurrentEventsCount: `${ECONOMIC_RELATIONS}/getPassportCurrentEventsCount`,
  getPassportPreviousEventsCount: `${ECONOMIC_RELATIONS}/getPassportPreviousEventsCount`,
  getPassportAgreements: `${ECONOMIC_RELATIONS}/getPassportAgreements`,
  getResponsibleAuthority: `${ECONOMIC_RELATIONS}/getResponsibleAuthority`,

  getTopRegionsAgreements: `${ECONOMIC_RELATIONS}/getTopRegionsAgreements`,
  getTopEffectiveRegions: `${ECONOMIC_RELATIONS}/getTopEffectiveRegions`,
  getTopRegionsByTurnover: `${ECONOMIC_RELATIONS}/getTopRegionsByTurnover`,
  getTopRegionsByExport: `${ECONOMIC_RELATIONS}/getTopRegionsByExport`,
  getTopRegionsByImport: `${ECONOMIC_RELATIONS}/getTopRegionsByImport`,
  getTopRegionsByEvents: `${ECONOMIC_RELATIONS}/getTopRegionsByEvents`,
  getTopRegionsByAgreements: `${ECONOMIC_RELATIONS}/getTopRegionsByAgreements`,
  getTopMonthRegionsByEvents: `${ECONOMIC_RELATIONS}/getTopMonthRegionsByEvents`,
  getTopMonthRegionsByAgreements: `${ECONOMIC_RELATIONS}/getTopMonthRegionsByAgreements`,
};

export const ECONOMIC_RELATIONS_MUTATIONS_NAME = {
  setPassportKeyData: 'setPassportKeyData',
  setPassportTradeTurnover: 'setPassportTradeTurnover',
  setPassportMainData: 'setPassportMainData',
  setPassportPartnerCountriesData: 'setPassportPartnerCountriesData',
  setPassportPartnerCountriesCount: 'setPassportPartnerCountriesCount',
  setPassportCurrentEvents: 'setPassportCurrentEvents',
  setPassportPreviousEvents: 'setPassportPreviousEvents',
  setPassportCurrentEventsCount: 'setPassportCurrentEventsCount',
  setPassportPreviousEventsCount: 'setPassportPreviousEventsCount',
  setPassportAgreements: 'setPassportAgreements',
  setResponsibleAuthority: 'setResponsibleAuthority',

  setTopRegionsAgreements: 'setTopRegionsAgreements',
  setTopEffectiveRegions: 'setTopEffectiveRegions',
};

export const ECONOMIC_RELATIONS_GETTERS_NAME = {
  getData: `${ECONOMIC_RELATIONS}/getData`,
  getPassportKeys: `${ECONOMIC_RELATIONS}/getPassportKeys`,
  getPassportPartnerCountries: `${ECONOMIC_RELATIONS}/getPassportPartnerCountries`,
  getPassportTradeTurnovers: `${ECONOMIC_RELATIONS}/getPassportTradeTurnovers`,
  getPassportMainInformation: `${ECONOMIC_RELATIONS}/getPassportMainInformation`,
  getPassportPartnersCount: `${ECONOMIC_RELATIONS}/getPassportPartnersCount`,
  getPassportCurEvents: `${ECONOMIC_RELATIONS}/getPassportCurEvents`,
  getPassportPrevEvents: `${ECONOMIC_RELATIONS}/getPassportPrevEvents`,
  getPassportCurEventsCount: `${ECONOMIC_RELATIONS}/getPassportCurEventsCount`,
  getPassportPrevEventsCount: `${ECONOMIC_RELATIONS}/getPassportPrevEventsCount`,
  getPassportListOfAgreements: `${ECONOMIC_RELATIONS}/getPassportListOfAgreements`,
  getResponsibleAuthorityInfo: `${ECONOMIC_RELATIONS}/getResponsibleAuthorityInfo`,

  topRegionsAgreementsInfo: `${ECONOMIC_RELATIONS}/topRegionsAgreementsInfo`,
  topEffectiveRegionsInfo: `${ECONOMIC_RELATIONS}/topEffectiveRegionsInfo`,
};
