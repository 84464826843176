<template>
  <div class="line-chart-percent">
    <p v-if="name" class="line-chart-percent__name">{{ name }}</p>
    <div class="line-chart-percent__line">
      <p
        v-for="(item, index) in seriesProp"
        :key="`chart-line-palette-${index}`"
        :style="`width: ${getPercent(item.data)}%; background: ${item.color}`"
        class="line-chart-percent__palette"
      >
        <span class="line-chart-percent__palette-info">{{ formatValue(getPercent(item.data)) }}%</span>
      </p>
    </div>
    <div class="line-chart-percent__legend">
      <p
        v-for="(item, index) in seriesProp"
        :key="`chart-line-legend-${index}`"
        class="line-chart-percent__legend-item"
      >
        <span class="line-chart-percent__legend-point" :style="`background: ${item.color}`"></span>
        <span>{{ item.name }}</span>
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LineChartPercent',
    props: {
      name: {
        type: String,
        default: '',
      },
      seriesProp: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      getTotal() {
        return this.seriesProp.reduce((prev, curr) => prev + curr.data, 0);
      },
    },
    methods: {
      getPercent(number) {
        const percent = (number / this.getTotal) * 100;
        return percent.toFixed(1);
      },
      formatValue(value) {
        return new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 1 })
          .format(+value);
      }
    },
  };
</script>

<style lang="scss" scoped>
  .line-chart-percent {
    &__name {
      font: 16px / 20px 'Fira Sans';
      margin-bottom: 39px;
      text-align: center;
    }

    &__line {
      width: 100%;
      display: flex;
    }

    &__palette {
      height: 20px;
      position: relative;

      &-info {
        position: absolute;
        bottom: 100%;
        margin-bottom: 9px;
        font: $fira-12;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &__legend {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 16px -12px 0;

      &-item {
        display: flex;
        align-items: center;
        white-space: nowrap;
        margin: 0 12px 8px;
      }

      &-point {
        width: 12px;
        height: 12px;
        margin-right: 12px;
      }

      span {
        font: 16px / 20px 'Fira Sans';
      }
    }
  }
</style>
